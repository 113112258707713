$user-type: 'buyer';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';
@import '../../scss/shared/layout';

.footerBottomRow {
    @include primary-page-width;
    padding-top: $sassy-spacing-medium;
    @include sassy-breakpoint-below(tablet-portrait) {
        width: 100%;
        margin: 0;
        padding: 0 $sassy-spacing-x-small $sassy-spacing-large $sassy-spacing-x-small;
    }
    @include sassy-font-body($size: x-small);
}

.legalLinkWrapper {
    display: flex;
    flex-flow: row wrap;
}

.recaptchaTermsWrapper {
    @include sassy-font-body($size: x-small);
    margin-top: $sassy-spacing-small;
    padding-left: $sassy-spacing-x-small;

    @include sassy-breakpoint-below(tablet-portrait) {
        padding-right: $sassy-spacing-x-small;
        margin-top: $sassy-spacing-x-small;
        color: $sassy-color-satan;
    }
}

.recaptchaLink {
    color: $sassy-color-noir;

    &:hover {
        color: $sassy-color-charcoal;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        text-align: center;
        color: $sassy-color-satan;
    }
}

.copyrightLink {
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-top: $sassy-spacing-small;
        text-align: center;
        color: $sassy-color-satan;
    }
}

.monogramIconWrapper {
    display: flex;
    justify-content: center;
    margin-top: $sassy-spacing-large;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-top: $sassy-spacing-small;
    }
}

.monogramIcon {
    width: 100%;
    height: $sassy-spacing-xx-large;
    text-align: center;
}
