$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';

.container {
    &.isMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.subheader {
    @include sassy-font-sub-header($type: normal);
}
