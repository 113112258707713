$user-type: 'buyer';
@import '~dibs-sassy/exports/_spacing';
@import '../styles/_generateColumnClasses';

$__INTERNAL_USE_ONLY_GRID_COLUMNS__: 12;
$__INTERNAL_USE_ONLY_GRID_DEVICES__: 'mobile', 'tablet-portrait', 'tablet-landscape', 'desktop';

:local {
    .col {
        position: relative;
        // Fix for bug in firefox when the column contains an image larger than the column: https://github.com/philipwalton/flexbugs/issues/41
        min-width: 0;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left: $sassy-spacing-x-small;
        padding-right: $sassy-spacing-x-small;
        // Will be overriden
        flex-grow: 1;
    }

    @include __INTERNAL_USE_ONLY_GENERATE_GRID_COLUMN_CLASSES__(
        $__INTERNAL_USE_ONLY_GRID_COLUMNS__,
        $__INTERNAL_USE_ONLY_GRID_DEVICES__
    );
}
