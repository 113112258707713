$user-type: 'buyer';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../../scss/shared/layout';

.footer {
    @include sassy-font-body($size: small);
    padding: $sassy-spacing-larger 0 $sassy-spacing-medium;
    @include sassy-breakpoint-above(desktop) {
        padding: $sassy-spacing-x-large 0 $sassy-spacing-xx-large;
        border-top: 1px solid $sassy-color-storm;
    }
    background: $sassy-color-white;
    color: $sassy-color-pitch-black;

    @media print {
        display: none;
    }
    @include sassy-breakpoint-below(tablet-portrait) {
        padding: 0;
    }
}

.footerRow {
    @include primary-page-width;
    padding-bottom: $sassy-spacing-xx-large;
    border-bottom: 1px solid $sassy-color-storm;
    @include sassy-breakpoint-below(tablet-portrait) {
        padding-bottom: $sassy-spacing-medium;
        border-color: $sassy-color-storm;
    }
}

.additionalLinkContainer {
    padding-top: $sassy-spacing-medium;
}
