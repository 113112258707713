$user-type: 'buyer';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';

.column {
    display: flex;
    flex-direction: column;
    @include sassy-breakpoint-below(tablet-portrait) {
        margin: $sassy-spacing-small 0 0 $sassy-spacing-small;
    }
}
.title {
    @include sassy-font-sub-header;
    margin: 0;
    margin-bottom: $sassy-spacing-small;
    color: $sassy-color-pitch-black;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}
