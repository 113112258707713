$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$socialColors: (
    facebook: $sassy-color-noir,
    pinterest: $sassy-color-noir,
    instagram: $sassy-color-noir,
);
$icon-size: 20px;
$mobile-icon-size: 30px;

.row {
    display: flex;
    flex-direction: row;
    height: 24px;
    align-items: center;
    @include sassy-font-sub-header($size: small, $type: normal);
    @include sassy-breakpoint-below(tablet-portrait) {
        height: $mobile-icon-size;
    }
    margin-top: $sassy-spacing-x-small;
    &.isMobile {
        margin-top: 0;
    }
}

.listItem {
    display: flex;
    margin-right: $sassy-spacing-small;

    &:last-child {
        margin-right: 0;
    }
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-right: $sassy-spacing-small;
    }
}

.item {
    display: flex;
    margin-right: $sassy-spacing-x-small;
    &:last-child {
        margin-right: 0;
    }
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-right: $sassy-spacing-small;
    }
}

.link {
    @include sassy-buyer-contrast-link-alternate;
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    background: transparent;
    transition: all 0.15s linear;
}

@each $social, $color in $socialColors {
    .#{$social} {
        color: $color;
    }

    .#{$social}:hover {
        color: $sassy-color-charcoal;
        background: $sassy-color-white;
    }
}
