$user-type: 'buyer';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

$icon-height: 24px;
$mobile-icon-height: 40px;

.link {
    height: $icon-height;
    overflow: hidden;
    display: flex;
    align-items: center;
    @include sassy-breakpoint-below(tablet-portrait) {
        height: $mobile-icon-height;
    }

    .icon {
        height: $icon-height;
        max-height: none;
        @include sassy-breakpoint-below(tablet-portrait) {
            height: $mobile-icon-height;
        }
    }
}

.placeholder {
    min-height: $icon-height;
    @include sassy-breakpoint-below(tablet-portrait) {
        min-height: $mobile-icon-height;
    }
}
