$user-type: 'buyer';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_breakpoints';

$specificGlobalSitesLinkSpacing: $sassy-spacing-x-small * 0.5;

.container {
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-bottom: $sassy-spacing-medium;
    }
    @include sassy-font-body($size: x-small, $type: none);
    display: flex;
    flex-flow: row wrap;
    padding: 0 $sassy-spacing-x-small;
}

.label {
    padding-right: $specificGlobalSitesLinkSpacing;
}

.linksContainer {
    display: flex;
    flex-flow: row wrap;
}

.spacer {
    padding: 0 $specificGlobalSitesLinkSpacing;
    margin: auto 0;
    &::after {
        content: '|';
    }
    &:last-child:after {
        content: '';
    }
}
