$user-type: 'buyer';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

.item {
    padding: 0;
    line-height: $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: small);
    }

    &.isConnectLink {
        @include sassy-breakpoint-below(tablet-portrait) {
            @include sassy-font-body($size: small);
        }
    }

    &.isLegalLink {
        margin: 0;
        padding: 0 $sassy-spacing-x-small;
        @include sassy-breakpoint-below(tablet-portrait) {
            margin-bottom: $sassy-spacing-x-small;
            text-decoration: underline;
            width: auto;
            @include sassy-font-body($size: x-small);

            &:first-child {
                width: auto;
            }
        }
    }

    &.isGlobalSitesLink {
        @include sassy-font-body($size: x-small);
        line-height: revert;
    }
}

.contact1stdibsLink {
    @include sassy-buyer-standard-link($underline: none);
}
